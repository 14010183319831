<template>
  <!-- This max-height style is a must becouse otherwise
  there is going to be a scroll bar that scrolls nowhere.
  Also, there is another height set at n-lauout has-sider
  that one is necessary as well :D this is somehow too complicated -->
  <n-layout style="height: 100vh">
    <n-layout-header bordered>
      <SiteHeader />
    </n-layout-header>
    <n-layout has-sider position="absolute" :style="isMobile ? 'top: 69px;' : 'top: 67px;'">
      <SiteLeftMenu />
      <n-layout :native-scrollbar="false" @scroll="handleScroll">
        <div class="progress-bar" id="progressBar"></div>
        <n-layout-content
          :content-style="this.isMobile ? 'padding: 8px;' : 'padding: 64px;'"
          :native-scrollbar="false"
        >
          <doc />
          <changePasswordNotification />
          <br v-if="this.isMobile">
        </n-layout-content>
        <n-back-top :right="16" />
        <n-layout-footer :style="this.isMobile ? 'padding: 8px;' : 'padding: 64px;'" bordered>
          <footer_ />
        </n-layout-footer>
      </n-layout>
    </n-layout>
  </n-layout>
  <!-- <notification /> -->
</template>


<script>

import SiteHeader from '@/components/SiteHeader.vue'
import SiteLeftMenu from '@/components/SiteLeftMenu.vue'
import doc from '@/pages/docs/doc'
import footer_ from '@/components/footer_.vue'
import changePasswordNotification from '@/components/changePasswordNotification.vue'

// import notification from '@/pages/docs/notification'

import { useIsMobile } from '@/utils/composables'
import { onMounted } from 'vue'
import { useStore } from 'vuex'

const isMobileRef = useIsMobile()

export default {
  name: 'root',
  components: {
    SiteHeader,
    SiteLeftMenu,
    doc,
    footer_,
    changePasswordNotification,
    // notification,
  },
  setup() {
    const store = useStore()
    onMounted(() => {
      // make the api calls needed to launch the doc page
      // 1 . fetch menu
      const menu = store.getters['menu/getMenu'].length
      menu ? null : store.dispatch('menu/getMenu')
      // 2. fetch userData
      // const userData = Object.keys(store.getters['userData/getUserData'].lessonData).length
      // userData === 0 ? store.dispatch('userData/getUserData', { userId: store.getters['auth/getAuthData'].userId}) : null
      store.dispatch('userData/getUserData', { userId: store.getters['auth/getAuthData'].userId})
      // 3. fetch updated lessons list
      // store.dispatch('lessons/getUpdatedLessons', store.getters['auth/getAuthData'].userId)
    });
    return {
      isMobile: isMobileRef,
      // notification,
    }
  },
  methods: {
    handleScroll: function(e) {
      var scrolled = (e.srcElement.scrollTop / (e.srcElement.scrollHeight - e.srcElement.clientHeight)) * 100;
      document.getElementById("progressBar").style.width = scrolled + "%";
    },
  }
}
</script>

<style>
.progress-bar {
    background: #fdb913;
    height: 5px;
    width: 0%;
    position: fixed;
    /* top: 50; */
    z-index: 1;
}
</style>
