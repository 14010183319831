<template>
  <n-space vertical>
    <div>
      <n-h4 prefix="bar" type="success" style="font-size: 85%; margin-top: 0px; margin-bottom: 0px">{{ new Date().getFullYear() }} Viešųjų pirkimų tarnyba</n-h4>
      <n-h4 prefix="bar" type="primary" style="font-size: 85%; margin-top: 0px; margin-bottom: 0px">Viešųjų pirkimų mokymų sistema „E-vedlys" </n-h4>
      <n-h4 prefix="bar" type="warning" style="font-size: 85%; margin-top: 0px; margin-bottom: 0px">🎓</n-h4>
      <br>
      <n-row><n-col style="width: 400px">
      <n-h4 prefix="bar" type="primary" style="font-size: 65%; margin-top: 0px; margin-bottom: 0px; font-weight: normal;">
        Metodinė priemonė parengta įgyvendinant Europos socialinio fondo ir Lietuvos Respublikos valstybės biudžeto lėšomis finansuojamą projektą „Viešųjų pirkimų efektyvumo didinimas metodinėmis priemonėmis“, projekto Nr. 10.1.2-ESFA-V-916-01-0004.
      </n-h4>
      </n-col></n-row>
    </div>
    <div>
      <img class="zoom" src="@/assets/eu-logo2-modified.png" style="max-width: 271px; height: auto;"/>
    </div>
  </n-space>
</template>

<script>

import { defineComponent } from 'vue'

export default defineComponent({
  components: {
  },
  setup () {
    return {
    }
  }
})
</script>

<style scoped>

.zoom {
  transition: transform .5s; /* Animation */
}

.zoom:hover {
  transform: scale(1.1);
}

</style>
