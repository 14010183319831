<template>
  <n-button text @click="showModal = true">{{ this.text }}</n-button>
  <n-modal
    v-model:show="showModal"
  >
    <n-card style="width: 500px;" size="huge" title="Pamiršote/ norite pasikeisti slaptažodį? 🛡️" :segmented="{content: 'hard'}">
      Įrašykite paskyros, kurios slaptažodį pamiršote arba norite pasikeisti, el. pašto adresą.<br><br>
      Į nurodytą el. paštą išsiųsime Jums laišką su instrukcija ką daryti toliau.
      <br><br>
      <n-form
        :label-width="80"
        :model="model"
        :rules="rules"
        size="medium"
        ref="formRef"
      >
        <n-form-item label="el. paštas" path="email">
          <n-input v-model:value="model.email" type="text" placeholder="el. paštas" />
        </n-form-item>
      </n-form>
      <template #footer>
        <n-spin size="small" :show="disableButton">
          <n-button @click="handleClick">Siųsti laišką su instrukcija</n-button>
        </n-spin>
        <!-- <n-button @click="handleClick" :disabled="finished">Siųsti laišką su instrukcija</n-button> -->
      </template>
    </n-card>
  </n-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useMessage } from 'naive-ui'
import { handleServerResponse } from '@/shared/serverResponseHandler'
// import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    text: String,
  },
  setup () {
    const router = useRouter()
    const message = useMessage()
    const modelRef = ref({email: null})
    const formRef = ref(null)
    const showModalRef = ref(false)
    const disableButtonRef = ref(false)
    const store = useStore()
    function validateEmail (rule, value) {
      var isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return isEmail.test(value)
    }
    return {
      rules: {
        email: [
          {
            required: true,
            message: 'Privaloma užpildyti',
            trigger: ['input', 'blur']
          },
          {
            validator: validateEmail,
            message: 'Įrašykite el. pašto adresą',
            trigger: 'input'
          }
        ]
      },
      handleClick (e) {
        console.log('clicked')
        disableButtonRef.value = true
        e.preventDefault()
        formRef.value.validate((errors) => {
          console.log(errors)
          if (!errors) {
            store.dispatch('auth/forgotPassword', modelRef.value)
            .then(resp => {
              const responseStatusMap = {
                200: function() {
                  // message.success(200)
                  message.success(resp.data.message, { duration: 4000 })
                  setTimeout(function() {
                    showModalRef.value = false
                    store.dispatch('auth/logout')
                    router.go('/auth')
                  }, 4000)
                },
                400: function(text) {
                  message.warning(400)
                  message.warning(text)
                }
              }
              handleServerResponse(resp, responseStatusMap)
              disableButtonRef.value = false
            })
          } else {
            disableButtonRef.value = false
          }
        })
      },
      showModal: showModalRef,
      model: modelRef,
      disableButton: disableButtonRef,
      formRef
    }
  }
})
</script>

<style>
</style>
