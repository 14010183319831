<template>
  <template />
</template>


<script>

import { defineComponent, computed, h } from 'vue'
import { useStore } from 'vuex'
import { useNotification } from 'naive-ui'
import forgotCard from '@/pages/auth/forgot_card.vue'

export default defineComponent({
  components: {
  },
  setup () {
    const notification = useNotification()
    const store = useStore()
    const authData = computed(() => store.getters['auth/getAuthData'])

    // get relevant dates
    var date = new Date()
    var passwordChangeDate = new Date(authData.value.passwordReset ? authData.value.passwordReset : authData.value.verified)

    if (passwordChangeDate < date.setMonth(date.getMonth() - 6)) {
      notification.warning({
        title: 'Svarbu!',
        description: "Rekomenduojame pasikeisti slaptažodį",
        content:
`Jūsų paskyros slaptažodis paskutinį kartą keistas prieš daugiau negu 6 mėnesius.

Rekomenduojame jį pasikeisti - spauskite pasirinktį esančią žemiau ⬇️.`,
        action: () => h(forgotCard, {text: 'Keisti slaptažodį'}),
      })

    }

    return {
      authData,
    }
  }
})
</script>

<style scoped>
</style>
